const appStrings = {
  // main products
  PRODUCTS: {
    MOBILE: 'Mobil',
    MOBILE_SUBSCRIPTION: 'Mobilabonnement', // alt version
    INSURANCE: 'Forsikring',
    REFINANCING: 'Refinansiering',
    LOAN: 'Forbrukslån',
  },
  PAGES: {
    ABOUT: 'Betaler du mer enn du må?',
    CHEAPEST_LOAN: 'Billigste forbrukslån: 3 vanlige spørsmål',
    MY_DEBT: 'Min gjeld',
    COOKIES: 'Cookies',
    DEBT_INFORMATION: 'Norsk Gjeldsinformasjon',
    DEBT_REGISTER: 'Gjeldsregisteret',
    PROPERTY_VALUE: 'Boligverdi',
    INSURANCE: 'Forsikring',
    LOAN: 'Forbrukslån',
    HOME: 'Hjem',
    INSURANCE_DATA_POLICY: 'Vilkår for fullmakt',
    NO_MATCH: '404',
    PARTNER_BANKS: 'Partnerbanker',
    PRIVACY: 'Personvernerklæring',
    REFINANCING: 'Refinansiering',
    TERMS: 'Brukervilkår',
    GJELDS: 'Gjeldsregisteret',
    ERROR_PAGE: '500',
    LIVE_OFFERS: 'Tilbud',
    PRIVACY_STATEMENT: 'Personvernerklæring',
    PRIVACY_STATEMENT_COMPETITION: 'Personvernerklæring - trekning',
    JOBS: 'Jobb',
    TERMS_MIN_SCOOPR: 'Brukervilkår Min Scoopr',
    TERMS_MORTGAGE: 'Brukervilkår Scoopr Boliglån',
    TERMS_REFINANCING: 'Brukervilkår Scoopr Refinansiering',
    MY_SCOOPR: 'Min Scoopr',
    MY_SCOOPR_LOGIN: 'Logg Inn - Min Scoopr',
    MY_SCOOPR_CONSENTS: 'Consents - Min Scoopr',
    MY_SCOOPR_DASHBOARD: 'Dashboard - Min Scoopr',
    MY_SCOOPR_MY_CONSENTS: 'Personvern - Min Scoopr',
    MY_SCOOPR_MY_DEBT: 'Min gjeld - Min Scoopr',
    MY_SCOOPR_EMAIL: 'E-post - Min Scoopr',
    MY_SCOOPR_PHONE: 'Mobilnummer - Min Scoopr',
    MY_SCOOPR_SAVINGS_POTENTIAL: 'Mitt sparepotensiale - Min Scoopr',
    MY_SCOOPR_LOGIN_LOADING: 'Loading - Min Scoopr',
    MY_SCOOPR_MY_CREDIT_SCORE: 'Min kredittscore - Min Scoopr',
  },
  // menu
  MENU: {
    HOME: 'Hjem',
    MY_SCOOPR: 'Min Scoopr',
    PRODUCTS: 'Produkter',
    PRODUCTS_INSURANCE: 'Forsikring',
    PRODUCTS_REFINANCING: 'Refinansiering',
    ABOUT: 'Om Scoopr',
    CUSTOMER_SERVICE: 'Kundeservice',
    TERMS_OF_USE: 'Brukervilkår',
    PRIVACY: 'Personvernerklæring',
    PRIVACY_STATEMENT: 'Personvernerklæring',
    COOKIES: 'Cookies',
    PARTNER_BANKS: 'Banker',
    UNSECURED_LOAN: 'Lån uten sikkerhet',
    CREDIT_CHECK_MYSELF: 'Kredittsjekke seg selv',
    DEBT_REGISTER: 'Gjeldsregisteret',
    DEBT_REGISTER_MENU: 'Gjeldsregister',
    SAVINGS_POTENTIAL: 'Sparepotensialet',
    DEBT_INFORMATION: 'Norsk Gjeldsinformasjon',
    MY_DEBT: 'Min gjeld',
    MY_ECONOMY: 'Din økonomi',
    CHEAPEST_LOAN: 'Billigste forbrukslån',
    ABOUT_LOAN: 'Lån',
    PRIVATE_LOAN: 'Privatlån',
    INTEREST_RATE: 'Rente',
    BEST_CONSUMER_LOAN: 'Beste forbrukslån',
    CREDIT_CARD_LOAN: 'Kredittkortlån',
    SMALL_LOAN: 'Smålån',
    DEBT_FREE: 'Gjeldfri',
    DEBT_OVERVIEW: 'Gjeldsoversikt',
    LOAN_MONEY: 'Låne penger',
    LOAN_CALCULATOR: 'Forbrukslånkalkulator',
    REPAYMENT_PERIOD: 'Nedbetalingstid',
    COMPARE_LOANS: 'Sammenligne lån',
    REFINANCING_LOANS: 'Refinansieringslån',
    MICROLOAN: 'Slik fungerer mikrolån',
    COMPARE_CONSUMER_LOANS: 'Sammenligne forbrukslån',
    SAVE_OR_REPAY_LOAN: 'Lønner det seg å spare eller nedbetale lån?',
    CONSUMER_LOAN_WITH_PAYMENT_REMARK: 'Forbrukslån med betalingsanmerkning',
    NOMINAL_AND_EFFECTIVE_INTEREST_RATE: 'Nominell og effektiv rente',
    PROPERTY_VALUE: 'Boligverdi',
    LOW_INTEREST_CONSUMER_LOANS: 'Forbrukslån lav rente',
    DECEMBER_2023_COMPETITION: 'Julekalender',
    JANUARY_2024_COMPETITION: 'Januar trekning',
    FEBRUARY_2024_COMPETITION: 'Februar trekning',
    MARCH_2024_COMPETITION: 'Gjeldvettreglene',
    MAY_2024_COMPETITION: 'Vårkampanje',
    SUMMER_2024_COMPETITION: 'Sommerkampanje',

    OPEN: 'åpne navigasjonsmenyen',
    CLOSE: 'lukk navigasjonsmenyen',
  },

  MOBILE_PRICES: 'Mobil øvrige priser',
  // COOKIES_PAGE
  COOKIES_PAGE: {
    TABLE_COLUMNS: ['Navn', 'Formål', 'Valgfrihet', 'Lagringstid*', 'Mottaker'],
  },
  // FAQ
  FAQ: {
    TITLE: 'Ofte stilte spørsmål',
    SEARCH_PLACEHOLDER: 'Søk...',
    SEARCH_LABEL: 'Søk på ofte stilte spørsmål',
    SEARCH_NO_RESULTS: 'Ingen treff',
    ALL_PRODUCTS: 'Alle produkter',
  },
  // VERIFIED REFINANCING
  VERIFIED_REFINANCING: {
    TITLE: 'Ditt sparepotensiale',
  },
  // CONTACT
  CONTACT: {
    HELP: 'Trenger du hjelp?',
    TITLE: 'Ta kontakt!',
    OPENING_TITLE: 'Åpningstider på telefon/e-post',
    // OPENING_TITLE: 'Åpningstider påske:',
    OPENING_CUSTOMER_SERVICE_TITLE: 'Åpningstider kundeservice',
    // OPENING_CUSTOMER_SERVICE_TITLE: 'Åpningstider påske:',
    OPENING: {
      REGULAR: {
        items: [
          { day: 'Mandag til torsdag', hours: '08 - 18' },
          { day: 'Fredag', hours: '08 - 17' },
          { day: 'Lørdag', hours: '10 - 14' },
          { day: 'Søndag', hours: '12 - 16' },
        ],
      },
      HOLIDAYS: {
        display: true,
        title: 'Åpningstider i påsken:',
        items: [
          { day: 'Palmesøndag', hours: '12 - 16' },
          { day: 'Skjærtorsdag', hours: 'Stengt' },
          { day: 'Langfredag', hours: 'Stengt' },
          { day: 'Påskeaften', hours: '10 - 14' },
          { day: '1. og 2. påskedag', hours: 'Stengt' },
        ],
      },
    },
    PHONE_TITLE: 'Ring',
    PHONE1: '418 00 000',
    PHONE2: '004741800000',
    COMPANY: [
      'Scoopr AS',
      'Org.nr. 917 864 152',
      'Scoopr Refinansiering AS',
      'Org.nr. 952 660 225',
    ],
    ADDRESS: ['Karenslyst Allé 2,', '0278 Oslo'],
    EMAIL: 'post@scoopr.no',
    GENERAL_QUESTIONS_TITLE: 'For generelle spørsmål/hendvendelser',
    GENERAL_QUESTIONS_EMAIL: 'post@scoopr.no',
    REFINANCING_QUESTIONS_TITLE: 'For spørsmål om lån',
    REFINANCING_QUESTIONS_EMAIL: 'post@scoopr.no',
    REFINANCING_QUESTIONS_EMAIL_TITLE: 'Refinansiering',
    INSURANCE_QUESTIONS_TITLE: 'For forsikringsspørsmål',
    INSURANCE_QUESTIONS_EMAIL: 'forsikring@scoopr.no',
    INSURANCE_QUESTIONS_EMAIL_TITLE: 'Forsikring',
    FACEBOOK_LABEL: 'Facebook',
    TIKTOK_LABEL: 'Tiktok',
  },
  ERROR: {
    MAIN: 'Beklager, noe gikk galt',
    NETWORK: 'Nettverksfeil',
    STH_WENT_WRONG: 'Noe gikk galt',
    TRY_AGAIN: 'Prøv igjen, eller kontakt support-teamet vårt for help',
  },
  LOG_IN: 'Min side',
  RIGHTS: 'Alle rettigheter forbeholdt.',
  LOADING: 'Laster, vennligst vent',
  DETAILS_PAGE: 'detajler side',
};

export default appStrings;
